import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  year?: number;
  week?: number;
  siteId?: number;
  shiftId?: number;
  departmentId?: number;
  employeeId?: number;
}

const reportUrl = '/rebel-stork/cost-plus-weekly';

export const WeeklyCostPlus: React.FC<IProps> = ({
  siteId,
  departmentId,
  shiftId,
  year,
  week,
  employeeId,
}) => {
  const { t } = useTranslation();
  const globallySelectedSites = useSelector(getGloballySelectedSites);

  const defaultOrderDetails = {
    order: 'asc' as const,
    orderBy: 'firstName',
  };

  const where = React.useMemo(
    () => ({
      siteId: { inq: siteId ? [siteId] : globallySelectedSites },
      ...(departmentId ? { departmentId } : {}),
      ...(shiftId ? { shiftId } : {}),
      ...(year ? { year } : {}),
      ...(week ? { week } : {}),
      ...(employeeId ? { employeeId } : {}),
    }),
    [
      departmentId,
      employeeId,
      globallySelectedSites,
      shiftId,
      siteId,
      week,
      year,
    ],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('cost_plus_weekly.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('cost_plus_weekly.last_name'),
      },
      {
        id: 'extEmployeeId',
        orderConfig: { orderBy: 'extEmployeeId' },
        label: t('cost_plus_weekly.extEmployeeId'),
      },
      {
        id: 'payRate',
        orderConfig: { orderBy: 'payRate' },
        label: t('cost_plus_weekly.payrate'),
      },
      {
        id: 'shift',
        orderConfig: { orderBy: 'shift' },
        label: t('cost_plus_weekly.shift'),
      },
      {
        id: 'department',
        orderConfig: { orderBy: 'department' },
        label: t('cost_plus_weekly.department'),
      },
      {
        id: 'site',
        orderConfig: { orderBy: 'site' },
        label: t('cost_plus_weekly.site'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('cost_plus_weekly.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('cost_plus_weekly.week'),
      },
      {
        id: 'client',
        orderConfig: { orderBy: 'client' },
        label: t('cost_plus_weekly.client'),
      },
      {
        id: 'sumTimeTotal',
        orderConfig: { orderBy: 'sumTimeTotal' },
        label: t('cost_plus_weekly.time_total'),
      },
      {
        id: 'regularHoursDec',
        orderConfig: { orderBy: 'regularHoursDec' },
        label: t('cost_plus_weekly.reg_hrs'),
      },
      {
        id: 'overtimeHoursDec',
        orderConfig: { orderBy: 'overtimeHoursDec' },
        label: t('cost_plus_weekly.ot_hrs'),
      },
      {
        id: 'nGroupCostFmt',
        disableSorting: true,
        orderConfig: { orderBy: 'nGroupCostFmt' },
        label: t('cost_plus_weekly.cost_plus'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <ReportTable
      data={data.items as any}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey={reportUrl}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};

export default WeeklyCostPlus;
